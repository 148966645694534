<template>
  <v-row class="mt-2 mx-2">
    <v-col cols="3">
      <v-card rounded="lg" class="py-2 px-4 mx-auto">
        <span class="card-main-text ft-32">1,105</span>
        <p class="ft-16 sub-text">daily visits</p>
        <v-icon class="card-icon">fa fa-eye</v-icon>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card rounded="lg" class="py-2 px-4 mx-auto">
        <span class="card-main-text ft-32">{{ messagesCount }}</span>
        <p class="ft-16 sub-text">total messages</p>
        <v-icon class="card-icon">fa-regular fa-comments</v-icon>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card rounded="lg" class="py-2 px-4 mx-auto">
        <span class="card-main-text ft-32">{{ newCount }}</span>
        <p class="ft-16 sub-text">new users</p>
        <v-icon class="card-icon">fa fa-user-plus</v-icon>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card rounded="lg" class="py-2 px-4 mx-auto">
        <span class="card-main-text ft-32">2</span>
        <p class="ft-16 sub-text">reports</p>
        <v-icon class="card-icon">fa-regular fa-flag</v-icon>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="6" md="4" sm="6">
      <v-card
        height="360"
        class="pa-2 my-4 ml-4"
        elevation="4"
        rounded="xl"
        color="white"
      >
        <canvas class="ma-2 pa-2" id="userDistributionChart"></canvas>
      </v-card>
    </v-col>
    <v-col cols="6" md="8" sm="12">
      <v-card
        height="360"
        class="pa-2 my-4 mr-4"
        elevation="4"
        rounded="xl"
        color="white"
      >
        <canvas class="ma-2 pa-2" id="userOnlineChart"></canvas>
      </v-card>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="8">
      <v-card class="ml-4 my-2">
        <v-card-title
          ><span class="text-bold subtitle-2" style="color: rgb(98, 0, 238)">
            Recent Logins
          </span>
        </v-card-title>

        <v-card-text>
          <v-table>
            <tbody>
              <tr
                style="height: 80px"
                v-for="user in recentLogins"
                :key="user.id"
              >
                <td style="border: none">
                  <v-img
                    width="60"
                    style="border-radius: 50%; border: 1px solid black"
                    :src="
                      user.photo
                        ? backendURL + user.photo
                        : backendURL + 'defaultProfile.jpeg'
                    "
                    cover
                  ></v-img>
                </td>
                <td style="border: none" class="mx-4">
                  {{ user.email }}
                </td>
                <td style="border: none" class="mx-1 text -right">
                  {{
                    user.lastSeen ? moment(user.lastSeen).fromNow() : 'recently'
                  }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col>
      <v-card class="mr-4 my-2">
        <v-card-title
          ><span class="text-bold subtitle-2" style="color: rgb(98, 0, 238)">
            New Registrations
          </span>
        </v-card-title>
        <v-card-text>
          <v-table>
            <tbody>
              <tr v-for="user in recentSignups" :key="user.id">
                <td style="border: none" class="mx-4">
                  {{ user.email }}
                </td>
                <td style="border: none" class="mx-1">
                  {{ moment(user.createdAt).fromNow() }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'chartjs-adapter-moment';
import axios from 'axios';
import { backendURL } from '@/config/config';
import auth from '@/helpers/auth';

export default {
  data() {
    return {
      recentLogins: [],
      recentSignups: [],
      onlineCount: 0,
      activeCount: 0,
      inactiveCount: 0,
      newCount: 0,
      messagesCount: 0,
      data: [],
      drawer: true,
      moment,
      backendURL,
    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = auth.adminToken();

    const userOnlineChart = document.getElementById('userOnlineChart');
    const userDistributionChart = document.getElementById(
      'userDistributionChart'
    );

    axios.get('/api/admins/users/recent').then((res) => {
      console.log(res.data.users);
      this.recentLogins = res.data.recentLogins;
      this.recentSignups = res.data.recentSignups;
    });

    axios.get('/api/admins/data').then((res) => {
      this.onlineCount = res.data.onlineCount;
      this.activeCount = res.data.activeCount;
      this.inactiveCount = res.data.inactiveCount;
      this.newCount = res.data.newCount;
      this.messagesCount = res.data.messagesCount;

      new Chart(userDistributionChart, {
        type: 'doughnut',
        data: {
          labels: ['Online', 'Active', 'Inactive'],
          datasets: [
            {
              label: '# of Users',
              data: [this.onlineCount, this.activeCount, this.inactiveCount],
              backgroundColor: [
                'rgb(236, 107, 86)',
                'rgb(71, 179, 156)',
                'rgb(121, 130, 185)',
              ],
              borderWidth: 1,
            },
          ],
        },
      });
    });
    axios.get('/api/admins/onlineUsers').then((res) => {
      console.log(res.data);

      new Chart(userOnlineChart, {
        type: 'line',
        data: {
          datasets: [
            {
              data: res.data,
              label: '# of users online',
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'timeseries',
            },
          },
        },
      });
    });
  },
};
</script>

<style>
.card-main-text {
  font-weight: 600;
  letter-spacing: -1px;
  color: rgb(98, 0, 238);
  font-family: Oswald;
}
.sub-text {
  color: rgb(130, 130, 130);
  font-weight: bold;
}
.card-icon {
  color: rgb(130, 130, 130);
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
}
.ft-16 {
  font-size: 16px;
}
.ft-32 {
  font-size: 32px;
}
</style>
