<template>
  <h2 class="text-center ma-4">Hello and Welcome to Messaging App Demo</h2>
  <v-card
    elevation="12"
    variant="outlined"
    class="mx-auto my-12"
    max-width="400"
    rounded="lg"
  >
    <v-card-title class="my-4 text-center">
      <h5>Enter your account information</h5>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="handleLogin">
        <v-text-field
          prepend-inner-icon="fa fa-envelope"
          v-model="email"
          variant="outlined"
          label="Email"
          type="email"
          class="mt-2"
          :readonly="loading"
          color="primary"
          :rules="[(v) => !!v || 'Please enter email']"
        />

        <v-text-field
          prepend-inner-icon="fa fa-lock"
          :append-inner-icon="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
          @click:append-inner="showPassword = !showPassword"
          v-model="password"
          variant="outlined"
          label="Password"
          :type="showPassword ? 'text' : 'password'"
          class="mt-2"
          :readonly="loading"
          color="primary"
          :rules="[(v) => !!v || 'Please enter password']"
        />

        <!-- <router-link
          to="/forgot-password"
          style="color: grey; font-size: 14px"
          class="mx-2"
          >Forgot password?</router-link
        >

        <v-spacer></v-spacer> -->

        <router-link
          to="/register"
          style="color: grey; font-size: 14px"
          class="mx-2"
          >Don't have an account?</router-link
        >

        <v-btn
          type="submit"
          :loading="loading"
          height="48"
          class="my-4"
          elevation="2"
          color="primary"
          block
        >
          Login
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
  <v-snackbar v-model="snackbar" color="error">{{ sbMsg }}</v-snackbar>
</template>

<script>
import auth from '../helpers/auth';

export default {
  components: {},
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      loading: false,
      snackbar: false,
      sbMsg: '',
    };
  },
  methods: {
    handleLogin() {
      if (!this.$refs.form.isValid) return;

      this.loading = true;

      try {
        auth.login(this.email, this.password, (loggedIn, message, token) => {
          if (loggedIn) {
            this.$router.push('/home');
          } else {
            this.sbMsg = message;
            this.snackbar = true;
            this.loading = false;
          }
        });
      } catch (err) {
        this.sbMsg = 'An error occurred, please try again';
        this.snackbar = true;
        this.loading = false;
      }
    },
  },
  mounted() {},
};
</script>
