<template>
  <div class="text-center">
    <h1 class="pa-auto ma-auto">:( Page Not Found</h1>
    <h3>Sorry, this page does not exist.</h3>
    <div class="mt-12">
      <router-link to="/">&lt;Back To Home</router-link>
    </div>
  </div>
</template>

<script></script>

<style scoped>
a {
  color: gray;
}
a:hover {
  color: gray;
}
</style>
