import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { backendURL } from '../config/config';
import { socket } from '@/socket';
import Cookies from 'js-cookie';

axios.defaults.baseURL = backendURL;

export default {
  login(email, password, cb) {
    axios
      .post('/api/users/login', { email, password })
      .then((res) => {
        if (!res.data.error) {
          Cookies.set('token', res.data.token, { secure: true });

          socket.emit('join', this.user().id);
          cb(true, 'success', res.data.token);
        } else {
          cb(false, res.data.message);
        }
      })
      .catch(() => {
        cb(false, 'An error occurred, please try again');
      });
  },

  register(data, cb) {
    axios
      .post('/api/users/register', data)
      .then((res) => {
        if (!res.data.error && res.data.token) {
          Cookies.set('token', res.data.token, { secure: true });

          socket.emit('join', this.user().id);
          cb(true, 'success');
        } else {
          cb(false, res.data.message);
        }
      })
      .catch(() => {
        cb(false, 'An error occurred, please try again');
      });
  },

  adminLogin(email, password, cb) {
    axios
      .post('/api/admins/login', { email, password })
      .then((res) => {
        if (res.data.token) {
          Cookies.set('adminToken', res.data.token, {
            path: '/admin',
            secure: true,
          });

          socket.emit('adminOnline');
          cb(true, 'success');
        } else {
          cb(false, res.data.message);
        }
      })
      .catch(() => {
        cb(false, 'An error occurred, please try again');
      });
  },

  token() {
    return Cookies.get('token');
  },

  adminToken() {
    return Cookies.get('adminToken');
  },

  async authenticated() {
    try {
      const res = await axios.get('/api/users/verify/user', {
        headers: {
          Authorization: `${this.token()}`,
        },
      });
      if (res.data.error) {
        console.log(res.data.message);
        throw new Error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      Cookies.remove('token');
      return false;
    }
    return !!Cookies.get('token');
  },
  user() {
    if (!Cookies.get('token')) {
      return;
    }

    return jwtDecode(this.token()).user;
  },
  logout() {
    socket.disconnect();
    Cookies.remove('token');
  },
};
