<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-table class="pa-2 ma-2">
    <thead>
      <tr>
        <th class="text-left">ID</th>
        <th class="text-left">Name</th>
        <th class="text-left">Email</th>
        <th class="text-center">Photo</th>
        <th class="text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr style="height: 120px" v-for="item in users" :key="item.name">
        <td style="text-transform: uppercase">
          {{ item.hash }}
          <v-chip
            v-if="
              new Date() - new Date(item.createdAt) < 7 * 24 * 60 * 60 * 1000
            "
            class="float-right"
            size="small"
            variant="elevated"
            color="green-darken-3"
            >New</v-chip
          >
        </td>
        <td>{{ item.fullName }}</td>
        <td>{{ item.email }}</td>
        <td>
          <v-img
            :src="item.photo ? backendURL + item.photo : ''"
            :lazy-src="item.photo ? backendURL + item.photo : ''"
            height="110"
          >
          </v-img>
        </td>
        <td class="text-center" style="max-width: 150px">
          <v-btn
            size="small"
            class="ma-1"
            variant="outlined"
            color="primary-darken-1"
            prepend-icon="fa fa-eye"
            @click="handleView(item)"
            >View</v-btn
          >
          <v-btn
            @click="handleRestoreUser(item.hash)"
            size="small"
            class="ma-1"
            variant="outlined"
            color="green"
            prepend-icon="fa fa-rotate-right"
            >Restore</v-btn
          >
        </td>
      </tr>
    </tbody>
  </v-table>

  <v-dialog v-model="userDetailsDialog" width="600">
    <v-card>
      <v-card-title>
        {{ selectedUser.fullName }}
        <v-chip
          v-if="
            new Date() - new Date(selectedUser.createdAt) <
            7 * 24 * 60 * 60 * 1000
          "
          class="my-0 py-0"
          size="x-small"
          variant="elevated"
          color="green"
          >New</v-chip
        >
        <v-btn
          @click="userDetailsDialog = !userDetailsDialog"
          class="float-right ma-0 pa-0"
          size="x-small"
          variant="elevated"
          icon="fa fa-xmark"
          color="red"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <span style="text-transform: uppercase">
          <span class="text-bold"> id: </span>
          {{ selectedUser.hash }}</span
        >
        <span class="d-block">
          <span class="text-bold"> Joined:</span>
          {{
            moment(selectedUser.createdAt).format('ddd DD/MM/YY [at] HH:mm')
          }}</span
        >
        <span class="d-block">
          <span class="text-bold"> Last Seen: </span>
          {{
            selectedUser.lastSeen
              ? moment(selectedUser.lastSeen).format('ddd DD/MM/YY [at] HH:mm')
              : 'N/A'
          }}</span
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { backendURL } from '@/config/config';
import auth from '@/helpers/auth';

export default {
  components: {},
  data() {
    return {
      users: [],
      selectedUser: {},
      userDetailsDialog: false,
      moment,
      backendURL,
    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = auth.adminToken();

    axios.get('/api/admins/users?deleted=true&suspended=false').then((res) => {
      console.log(res.data);
      this.users = res.data.users;
    });
  },
  methods: {
    handleView(user) {
      // console.log(user);
      this.selectedUser = user;
      this.userDetailsDialog = true;
    },
    handleRestoreUser(userHash) {
      axios.delete(`/api/admins/users/${userHash}`).then((res) => {
        console.log(res.data.message);
        this.users = this.users.filter((user) => user.hash != userHash);
      });
    },
  },
};
</script>
<style scoped>
* {
  font-family: Helvetica;
}
</style>
