<template>
  <h2 class="text-center ma-4">Welcome to Messaging App Demo</h2>
  <v-card
    elevation="4"
    variant="outlined"
    class="mx-auto my-12"
    width="450"
    max-width="95%"
    rounded="lg"
  >
    <v-card-title class="my-4 text-center">
      <h3>Fill in this form to register</h3>
    </v-card-title>
    <v-card-text>
      <v-form class="mt-10" ref="form" @submit.prevent="handleRegister">
        <v-row>
          <v-col class="py-2 pl-4 pr-1" cols="6">
            <v-text-field
              v-model="firstName"
              prepend-inner-icon="fa fa-user"
              variant="outlined"
              label="First Name"
              :readonly="loading"
              counter
              maxlength="15"
              color="primary"
              :rules="[
                (v) => !!v || 'This field is required!',
                (v) => nameRegex.test(v) || 'Invalid name',
              ]"
            />
          </v-col>
          <v-col class="py-2 pr-4 pl-1" cols="6">
            <v-text-field
              v-model="lastName"
              prepend-inner-icon="fa fa-user"
              variant="outlined"
              label="Surname"
              :readonly="loading"
              counter
              maxlength="15"
              color="primary"
              :rules="[
                (v) => !!v || 'This field is required!',
                (v) => nameRegex.test(v) || 'Invalid name',
              ]"
            />
          </v-col>
          <v-col class="py-2 px-3" cols="12">
            <v-text-field
              prepend-inner-icon="fa fa-envelope"
              v-model="email"
              variant="outlined"
              label="Email"
              type="email"
              :readonly="loading"
              color="primary"
              :rules="[
                (v) => !!v || 'Please enter email!',
                (v) => emailRegex.test(v) || 'Enter a valid email!',
              ]"
            />
          </v-col>

          <v-col class="pt-2 mb-0 px-3" cols="12">
            <v-text-field
              prepend-inner-icon="fa fa-lock"
              :append-inner-icon="
                showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'
              "
              @click:append-inner="showPassword = !showPassword"
              v-model="password"
              variant="outlined"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :readonly="loading"
              color="primary"
              :rules="[(v) => !!v || 'Please enter password!']"
            />
            <router-link
              to="/"
              style="color: grey; font-size: 14px"
              class="mx-2"
              >I already have an account</router-link
            >
          </v-col>

          <v-btn
            type="submit"
            :loading="loading"
            height="48"
            width="300"
            class="mt-auto mx-auto mb-2"
            elevation="2"
            color="primary"
          >
            Sign up
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-snackbar v-model="snackbar" color="error">{{ sbMsg }}</v-snackbar>
</template>

<script>
import auth from '../helpers/auth';

export default {
  components: {},
  data() {
    return {
      firstName: '',
      lastName: '',
      nameRegex: /^[A-Za-zıüö]+$/,
      email: '',
      emailRegex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      password: '',
      showPassword: false,
      loading: false,
      snackbar: false,
      sbMsg: '',
    };
  },
  methods: {
    handleRegister() {
      if (!this.$refs.form.isValid) return;

      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email.toLowerCase(),
        password: this.password,
      };

      this.loading = true;

      auth.register(data, (loggedIn, message) => {
        if (loggedIn) {
          this.$router.push('/home');
        } else {
          this.sbMsg = message;
          this.snackbar = true;
          this.loading = false;
        }
      });
    },
  },
  mounted() {},
};
</script>
