import { reactive } from 'vue';
import { io } from 'socket.io-client';
import { backendURL } from './config/config';

// const URL = 'http://localhost:3000';
const URL = backendURL;

export const state = reactive({ msg: {}, userRead: '', onlineUsers: [] });
export const socket = io(URL);

socket.on('newMsg', (message) => {
  state.msg = message;
});

socket.on('messagesRead', (userId) => {
  console.log('Message seen');
  state.userRead = userId;
});

socket.on('markAsRead', (message) => {
  state.readMsg = message;
});

socket.on('onlineUsers', (onlineUsers) => {
  state.onlineUsers = onlineUsers;
});
