<template>
  <v-container>
    <v-row style="height: 95vh" align="center">
      <v-col>
        <v-card
          elevation="12"
          variant="outlined"
          class="mx-auto"
          max-width="400"
          rounded="lg"
        >
          <v-card-title class="my-4 text-center">
            <h4>Change Password</h4>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" @submit.prevent="handleChangePassword">
              <v-text-field
                v-model="oldPassword"
                variant="solo-filled"
                label="Old Password"
                type="password"
                class="mt-2"
                :rules="[(v) => !!v || 'This field is required!']"
                :readonly="loading"
                color="primary"
              />

              <v-text-field
                v-model="password"
                variant="solo-filled"
                label="New Password"
                type="password"
                class="mt-2"
                :rules="[(v) => !!v || 'This field is required!']"
                :readonly="loading"
                color="primary"
              />

              <v-text-field
                v-model="password2"
                variant="solo-filled"
                label="Confirm New Password"
                type="password"
                class="mt-2"
                :rules="[
                  (v) => !!v || 'This field is required!',
                  (v) => v == password || 'Passwords must match!',
                ]"
                :readonly="loading"
                color="primary"
              />

              <v-btn
                type="submit"
                :loading="loading"
                height="48"
                class="my-4"
                elevation="2"
                color="primary"
                block
              >
                Change
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar" :color="sbColor">{{ sbMsg }}</v-snackbar>
</template>

<script>
import auth from '@/helpers/auth';
import axios from 'axios';

export default {
  components: {},
  data() {
    return {
      password: '',
      oldPassword: '',
      password2: '',
      loading: false,
      snackbar: false,
      sbColor: 'error',
      sbMsg: '',
    };
  },
  methods: {
    handleChangePassword() {
      if (!this.$refs.form.isValid) return;

      this.loading = true;

      try {
        axios
          .post('/api/admins/update-password', {
            oldPassword: this.oldPassword,
            password: this.password,
          })
          .then((res) => {
            if (!res.data.errors) {
              this.sbMsg = res.data.message;
              this.sbColor = 'indigo';
              this.snackbar = true;
            } else {
              this.sbMsg = res.data.message;
              this.sbColor = 'error';
              this.snackbar = true;
            }
            this.loading = false;
          });
      } catch (err) {
        this.sbMsg = 'An error occurred, please try again';
        this.snackbar = true;
        this.loading = false;
      }
    },
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = auth.adminToken();
  },
};
</script>
