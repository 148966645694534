<template>
  <Navbar />
  <h2 class="mt-4 text-center">Chats</h2>
  <div
    style="height: 80vh"
    v-if="loading"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular size="100" width="10" indeterminate color="primary">
    </v-progress-circular>
  </div>

  <v-container v-else>
    <v-text-field
      v-model="searchQuery"
      class="ma-4 mt-0"
      clearable
      variant="solo"
      rounded="xl"
      placeholder="Search Users"
    ></v-text-field>
    <v-row>
      <v-col
        xs="12"
        sm="3"
        md="4"
        lg="3"
        v-for="user in filteredUsers"
        :key="user._id"
      >
        <v-card align="center" height="225" rounded="lg">
          <v-menu location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                variant="plain"
                style="position: absolute; right: 5px; top: 5px"
                icon="fa fa-ellipsis-vertical"
                v-bind="props"
              ></v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleReport(user)">
                <span style="color: rgb(100, 100, 100)">
                  <v-icon>fa-regular fa-flag</v-icon> Report
                </span>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-card-title class="mt-4">
            <v-img
              style="border-radius: 50%; border: 2px solid gray"
              width="90"
              height="90"
              :src="
                user.photo
                  ? backendURL + user.photo
                  : require('@/assets/generic-profile-pic.jpg')
              "
              :alt="require('@/assets/generic-profile-pic.jpg')"
              cover
            >
            </v-img>
          </v-card-title>
          <v-card-text class="mt-2 overflow-hidden">
            <p style="max-height: 25px" class="overflow-hidden">
              {{ user.fullName }}
            </p>
            <div>
              <v-btn
                color="primary-darken-1"
                @click="gotoChat(user.hash)"
                class="ma-1"
                icon
              >
                <v-icon size="100%"> fa fa-message</v-icon></v-btn
              >
              <v-btn
                color="secondary-darken-1"
                @click="gotoProfile(user.hash)"
                class="ma-1"
                icon
                ><v-icon size="100%">fa fa-circle-info</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog width="75%" v-model="reportDialog">
    <v-card>
      <v-card-title> Report a user </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-textarea
          :placeholder="`What did ${selectedUser.fullName} do?`"
          counter
          maxlength="250"
          v-model="reportReason"
        >
        </v-textarea>
        <v-btn
          @click="submitReport()"
          variant="elevated"
          color="primary"
          class="ma-2"
          >Submit</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-snackbar v-model="snackbar" :color="sbColor">{{ sbMessage }}</v-snackbar>
</template>

<script>
import Navbar from '@/components/NavBar.vue';

import axios from 'axios';
import { backendURL } from '../config/config';
import auth from '../helpers/auth';

axios.defaults.baseURL = backendURL;

export default {
  data() {
    return {
      users: [],
      selectedUser: {},
      searchQuery: '',
      reportDialog: false,
      reportReason: '',
      sbColor: '',
      sbMessage: '',
      snackbar: false,
      loading: true,
      backendURL,
    };
  },
  components: { Navbar },
  mounted() {
    axios
      .get('/api/users/all', { headers: { Authorization: auth.token() } })
      .then((res) => {
        this.users = res.data.filter((item) => item._id !== auth.user().id);
        this.loading = false;
      });
  },
  methods: {
    gotoChat(hash) {
      this.$router.push(`/chat/${hash}`);
    },
    gotoProfile(hash) {
      this.$router.push(`/profile/${hash}`);
    },
    handleReport(user) {
      this.selectedUser = user;
      this.reportDialog = true;
    },
    submitReport() {
      axios
        .post(
          `/api/users/report/${this.selectedUser._id}`,
          {
            reason: this.reportReason,
          },
          { headers: { Authorization: auth.token() } }
        )
        .then((res) => {
          if (!res.data.error) {
            this.sbColor = 'indigo';
            this.sbMessage = res.data.message;
            this.snackbar = true;
            this.reportDialog = false;
          } else {
            this.sbColor = 'error';
            this.sbMessage = res.data.message;
            this.snackbar = true;
          }
        })
        .catch(() => {
          this.sbColor = 'error';
          this.sbMessage = 'Something happended, please try again';
          this.snackbar = true;
        });
    },
  },
  computed: {
    filteredUsers() {
      if (this.searchQuery == '') return this.users;

      return this.users.filter(
        (user) =>
          user.fullName
            .toLowerCase()
            .indexOf(this.searchQuery.toLowerCase()) !== -1
      );
    },
  },
  watch: {
    reportDialog() {
      this.reportReason = '';
    },
  },
};
</script>

<style></style>
