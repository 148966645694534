<template>
  <Navbar />

  <h2 class="mt-4 text-center">Profile</h2>
  <v-card
    rounded="xl"
    elevation="4"
    class="mx-auto my-2"
    height="500"
    max-height="75%"
    max-width="95%"
    width="450"
  >
    <v-img height="150" :src="require('@/assets/Clouds.jpg')" cover> </v-img>
    <v-img
      style="
        position: absolute;
        border-radius: 50%;
        border: 2px solid gray;
        box-shadow: 0 0 5px black;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
      "
      width="80"
      height="80"
      :src="
        user.photo
          ? backendURL + user.photo
          : require('@/assets/generic-profile-pic.jpg')
      "
      :alt="require('@/assets/generic-profile-pic.jpg')"
      cover
    >
    </v-img>

    <v-card-title class="pb-0 mt-2 text-center">
      <div style="font-size: 40px; font-weight: 600; letter-spacing: -3px">
        {{ user.fullName }}
      </div>
    </v-card-title>

    <v-card-text class="mt-2 text-center">
      <p>{{ user.email }}</p>
      <v-row class="my-auto">
        <v-col class="pb-0" :cols="12" sm="6" md="6">
          Joined: {{ moment(user.createdAt).format('MMM YYYY') }}
        </v-col>
        <v-col class="pb-0" :cols="12" sm="6" md="6">
          Last Seen: Dec 2023
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="userHash == auth.user().hash"
        @click="updateProfileDialog = true"
        icon
        variant="elevated"
        color="secondary-darken-1"
        style="margin-top: 40%"
        class="mx-auto my-auto"
      >
        <v-icon size="100%">fa fa-pen</v-icon>
      </v-btn>
      <v-btn
        v-else
        @click="gotoChat(userHash)"
        icon
        variant="elevated"
        color="primary-darken-1"
        style="margin-top: 40%"
        class="mx-auto my-auto"
      >
        <v-icon size="100%">fa fa-message</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>

  <v-dialog v-model="updateProfileDialog">
    <v-card class="mx-auto" width="600" max-width="90%">
      <v-card-title
        >Update Profile
        <v-btn
          @click="updateProfileDialog = !updateProfileDialog"
          class="float-right ma-0 pa-0"
          size="x-small"
          variant="elevated"
          icon="fa fa-xmark"
          color="red"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleUpdateProfile">
          <v-row>
            <v-col class="py-1" cols="6">
              <v-text-field
                label="First Name"
                v-model="user.firstName"
                :rules="[(v) => !!v || 'First name cannot be empty']"
              ></v-text-field>
            </v-col>
            <v-col class="py-1" cols="6">
              <v-text-field
                label="Last Name"
                v-model="user.lastName"
                :rules="[(v) => !!v || 'Last name cannot be empty']"
              ></v-text-field>
            </v-col>

            <v-col class="pb-0 pt-1" cols="12">
              <v-file-input
                :accept="['image/jpeg', 'image/png']"
                chips
                label="Profile Photo"
                prepend-icon="fa-solid fa-paperclip"
                ref="fileupload"
                @change="this.onFilesPicked"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              type="submit"
              class="mx-auto"
              variant="elevated"
              color="primary-darken-1"
            >
              Update
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>

  <v-snackbar v-model="snackbar" :color="color">{{ message }}</v-snackbar>
</template>

<script>
import Navbar from '@/components/NavBar.vue';

// import auth from '../helpers/auth';
import axios from 'axios';
import { backendURL } from '@/config/config';
import moment from 'moment';
import auth from '@/helpers/auth';

axios.defaults.baseURL = backendURL;

export default {
  data() {
    return {
      userHash: this.hash,
      user: {},
      profilePhoto: '',
      password: '',
      oldPassword: '',
      updateProfileDialog: false,
      snackbar: false,
      color: '',
      message: '',
      auth,
      moment,
      backendURL,
    };
  },
  props: ['hash'],
  components: { Navbar },
  mounted() {
    if (!this.userHash || this.userHash == undefined)
      this.userHash = auth.user().hash;

    axios.get(`/api/users/hash/${this.userHash}`).then((res) => {
      this.user = res.data;
    });
  },
  methods: {
    handleUpdateProfile() {
      if (!this.$refs.form.isValid) return;
      if (this.profilePhoto) {
        const data = new FormData();

        data.append('image', this.profilePhoto);
        axios
          .post('/api/users/uploadphoto', data, {
            headers: { Authorization: auth.token() },
          })
          .then((res) => {
            if (!res.data.error) {
              this.snackbar = true;
              this.color = 'success';
              this.message = 'Photo updated successfully';
              this.user.photo = res.data.photo;
              this.updateProfileDialog = false;
            } else {
              this.snackbar = true;
              this.color = 'error';
              this.message = 'Error updating photo';
            }
          });
      }
      axios
        .post(
          '/api/users/update',
          { firstName: this.user.firstName, lastName: this.user.lastName },
          {
            headers: {
              Authorization: auth.token(),
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            this.snackbar = true;
            this.color = 'success';
            this.message = 'Name updated successfully';
            this.user.fullName = this.user.firstName + ' ' + this.user.lastName;
            this.updateProfileDialog = false;
          } else {
            this.snackbar = true;
            this.color = 'error';
            this.message = 'Error updating name';
          }
        });
    },
    gotoChat(hash) {
      this.$router.push(`/chat/${hash}`);
    },
    onFilesPicked(f) {
      console.log(f.target.files[0]);
      this.profilePhoto = f.target.files[0];
    },
  },
};
</script>

<style></style>
