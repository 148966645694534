<template>
  <v-container>
    <v-row style="height: 95vh" align="center">
      <v-col>
        <v-card
          elevation="12"
          variant="outlined"
          class="mx-auto"
          max-width="400"
          rounded="lg"
        >
          <v-card-title class="my-4 text-center">
            <h4>Admin Panel</h4>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" @submit.prevent="handleLogin">
              <v-text-field
                prepend-inner-icon="fa-regular fa-user"
                v-model="email"
                variant="solo-filled"
                label="Email"
                class="mt-2"
                :readonly="loading"
                color="primary"
              />

              <v-text-field
                prepend-inner-icon="fa fa-lock"
                v-model="password"
                variant="solo-filled"
                label="Password"
                type="password"
                class="mt-2"
                :readonly="loading"
                color="primary"
              />

              <v-btn
                type="submit"
                :loading="loading"
                height="48"
                class="my-4"
                elevation="2"
                color="primary"
                block
              >
                Login
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar" color="error">{{ sbMsg }}</v-snackbar>
</template>

<script>
import auth from '@/helpers/auth';

export default {
  components: {},
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      snackbar: false,
      sbMsg: '',
    };
  },
  methods: {
    handleLogin() {
      if (!this.$refs.form.isValid) return;

      this.loading = true;

      try {
        auth.adminLogin(this.email, this.password, (loggedIn, message) => {
          if (loggedIn) {
            this.$router.push('/admin/home');
          } else {
            this.sbMsg = message;
            this.snackbar = true;
            this.loading = false;
          }
        });
      } catch (err) {
        this.sbMsg = 'An error occurred, please try again';
        this.snackbar = true;
        this.loading = false;
      }
    },
  },
  mounted() {},
};
</script>
