<template>
  <router-view />
</template>

<script>
import auth from './helpers/auth';
import { socket } from './socket';

export default {
  data() {
    return {
      user: auth.user(),
    };
  },
  components: {},
  mounted() {
    if (this.user) socket.emit('join', this.user.id);
  },
  beforeUnmount() {
    socket.disconnect();
  },
};
</script>

<style>
@font-face {
  font-family: 'Roboto';
  src: url('~@/assets/fonts/Roboto-Bold.ttf') format('truetype');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body::-webkit-scrollbar {
  display: none;
}
.subtitle-1 {
  font-size: 36px;
}
.subtitle-2 {
  font-size: 24px;
}
.subtitle-3 {
  font-size: 20px;
}
.text-bold {
  font-weight: bold;
}
</style>
