<template>
  <!-- md and higher navbar -->
  <v-toolbar elevation="1" color="deep-purple-darken-4">
    <v-toolbar-side-icon class="hidden-md-and-up">
      <v-btn text @click.stop="drawer = !drawer">
        <v-icon class="">fa-solid fa-bars</v-icon>
      </v-btn>
    </v-toolbar-side-icon>

    <v-layout class="hidden-md-and-up">
      <v-navigation-drawer :temporary="true" app clipped v-model="drawer">
        <template v-slot:prepend>
          <v-list-item
            lines="two"
            :prepend-avatar="
              user.photo
                ? backendURL + user.photo
                : require('@/assets/generic-profile-pic.jpg')
            "
            :title="auth.user().fullName"
            subtitle="Logged in"
          ></v-list-item>
        </template>
        <v-divider></v-divider>
        <v-list-item to="/home" class="dash-nav-link"> Home </v-list-item>
        <v-list-item to="/profile" class="dash-nav-link"> Profile </v-list-item>
        <!-- <v-list-item to="/chat" class="dash-nav-link"> Chat </v-list-item> -->
        <v-list-item to="/about" class="dash-nav-link"> About </v-list-item>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

        <v-list-item @click="handleLogout" icon>
          <v-icon size="20">fa fa-right-from-bracket</v-icon>
          <span class="ml-2">Logout</span>
        </v-list-item>
      </v-navigation-drawer>
    </v-layout>

    <v-toolbar-items class="hidden-sm-and-down mx-auto">
      <v-btn to="/home" class="dash-nav-link"> Home </v-btn>
      <v-btn to="/profile" class="dash-nav-link"> Profile </v-btn>
      <!-- <v-btn to="/chat" class="dash-nav-link"> Chat </v-btn> -->
      <v-btn to="/about" class="dash-nav-link"> About </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-btn @click="unseen = false" icon>
      <v-icon size="100%">fa fa-bell</v-icon>
      <div
        v-if="unseen"
        style="
          position: absolute;
          top: 12px;
          right: 12px;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          background: red;
        "
      ></div>
      <v-menu location="start" activator="parent">
        <v-list width="400" height="500" class="overflow-auto">
          <!-- no notification message -->
          <v-list-item
            v-if="notifications.length == 0"
            class="pa-0 ma-0"
            rounded="none"
          >
            <v-card variant="plain">
              <v-card-text class="d-flex justify-center subtitle-2">
                <v-icon size="20">fa fa-circle-info</v-icon>
                <span class="ml-1"> No notification </span>
              </v-card-text>
            </v-card>
          </v-list-item>

          <v-list-item
            v-else
            class="pa-0"
            rounded="none"
            v-for="notification in notifications"
            :key="notification._id"
          >
            <v-card
              :to="`/chat/${notification.sent_from.hash}`"
              style="cursor: pointer; border-bottom: 1px solid black"
              variant="plain"
            >
              <v-card-title class="mb-0">
                <v-icon size="20">fa fa-envelope</v-icon>
                <span class="text-bold subtitle-3">
                  New message from {{ notification.sent_from.firstName }}
                </span>
              </v-card-title>
              <v-card-text>{{ notification.content }}</v-card-text>
            </v-card>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <v-btn class="hidden-sm-and-down" @click="handleLogout" icon>
      <v-icon size="100%">fa fa-right-from-bracket</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { state } from '@/socket';
import auth from '@/helpers/auth';
import axios from 'axios';
import { backendURL } from '@/config/config';

axios.defaults.baseURL = backendURL;

export default {
  data() {
    return {
      drawer: false,
      unseen: false,
      notifications: [],
      notificationMenu: true,
      user: {},
      auth,
      backendURL,
    };
  },
  mounted() {
    axios
      .get(`/api/users/`, {
        headers: {
          Authorization: `${auth.token()}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.user = res.data;
      });
  },
  computed: {
    notification() {
      return state.msg;
    },
  },
  watch: {
    notification(val) {
      this.unseen = true;
      this.notifications.unshift(val);
    },
  },
  methods: {
    handleLogout() {
      auth.logout();
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
*::-webkit-scrollbar {
  display: none;
}
</style>
