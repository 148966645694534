<template>
  <Navbar />
  <div
    style="height: 90vh"
    v-if="loading"
    class="d-flex justify-center align-center"
  >
    <v-progress-circular size="100" width="10" indeterminate color="primary">
    </v-progress-circular>
  </div>

  <v-container v-else class="d-flex justify-center pb-0">
    <!-- Chat box -->
    <v-card
      v-if="userFound"
      elevation="4"
      class="px-4 pt-2 mb-0 pb-0"
      width="500"
    >
      <!-- Other user profile and name -->
      <v-card-title class="py-0">
        <v-img
          :width="50"
          :height="50"
          class="ma-2 mt-0 float-left"
          style="border-radius: 50%; border: 1px solid gray"
          :src="
            other.photo
              ? backendURL + other.photo
              : require('@/assets/generic-profile-pic.jpg')
          "
          :alt="require('@/assets/generic-profile-pic.jpg')"
          cover
        ></v-img>
        <v-row>
          <v-col cols="12" class="ma-0 pa-0">{{
            other ? other.fullName : ''
          }}</v-col>
          <v-col style="font-size: 16px; top: 25px" class="mx-2 my-0 pa-0">
            {{
              onlineStatus
                ? 'Online'
                : 'Last seen: ' + moment(other.lastSeen).fromNow()
            }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card
        variant="outlined"
        style="border: none; transform: rotateX(180deg)"
        class="px-4 py-2 overflow-auto"
        height="62vh"
      >
        <!-- No messages notification -->
        <div
          class="text-center justify-center"
          style="
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%) rotateX(-180deg);
          "
          v-if="messages.length == 0"
        >
          You haven't chatted before, say Hello 👋
        </div>

        <!-- Messages -->
        <div
          style="border: none; transform: rotateX(-180deg)"
          v-for="message in messages"
          :key="message._id"
        >
          <!-- Chips showing dates -->
          <div class="text-center">
            <v-chip v-if="message._id == firstMsgToday"> Today </v-chip>
            <v-chip v-else-if="message._id == firstMsgYesterday">
              Yesterday
            </v-chip>
            <v-chip
              v-else-if="
                firstMsgLastWeek().findIndex(
                  (item) => message._id == item._id
                ) != -1
              "
            >
              <div>
                {{ moment(message.createdAt).format('ddd') }}
              </div>
            </v-chip>
            <v-chip
              v-else-if="message._id == messages[messages.length - 1]._id"
            >
              Earlier
            </v-chip>
          </div>

          <!-- Message content -->
          <v-card
            style="cursor: pointer"
            :color="message.sent_to == auth.user().id ? '' : 'primary'"
            :class="
              message.sent_to == auth.user().id
                ? 'ma-2' + ' pa-0'
                : 'ml-auto mr-0 my-2'
            "
            rounded="lg"
            width="fit-content"
            min-height="60"
            min-width="80"
            max-width="300"
          >
            <v-menu
              open-on-hover
              activator="parent"
              location="top"
              transition="scale-transition"
              scroll-strategy="close"
            >
              <v-list elevation="1">
                <v-list-item>
                  <v-icon color="gray">fa-regular fa-circle-check</v-icon>
                  {{
                    message.receivedAt
                      ? moment(message.receivedAt).format(
                          'D MMM YY [at] h:mm a'
                        )
                      : '--'
                  }}
                </v-list-item>
                <v-list-item>
                  <v-icon color="success">fa-solid fa-circle-check</v-icon>
                  {{
                    message.readAt
                      ? moment(message.readAt).format('D MMM YY [at] h:mm a')
                      : '--'
                  }}</v-list-item
                >
              </v-list>
            </v-menu>
            <v-card-text class="pt-2 px-3">
              {{ message.content }}
              <span
                style="
                  font-size: 12px;
                  font-style: italic;
                  position: absolute;
                  right: 8px;
                  bottom: 2px;
                "
                >{{ moment(message.createdAt).format('h:mm a') }}</span
              >
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <!-- Text box -->
      <v-text-field
        :loading="msgLoading"
        rounded="lg"
        variant="solo"
        class="mx-2 mt-4"
        placeholder="Write a message"
        v-model="text"
        @keydown.enter="sendMessage"
      >
        <template v-slot:append-inner>
          <v-icon
            style="
              cursor: pointer;
              border-radius: 50%;
              background: blue;
              width: 45px;
              height: 45px;
            "
            color="white"
            justify="center"
            align="center"
            class="mr-2"
            icon="fa fa-send"
            @click="sendMessage"
          ></v-icon>
        </template>
      </v-text-field>
    </v-card>
    <v-card v-else class="my-12" width="600">
      <v-card-title>
        <h1 class="subtitle-1 ma-6 ml-0">No such user... yet</h1></v-card-title
      >
      <v-card-text>
        <span style="font-size: 20px" class="ma-6">
          User not found, Make sure you entered the right user ID or try again
          later.</span
        >
      </v-card-text>
    </v-card>
  </v-container>

  <v-snackbar v-model="snackbar" :color="color">{{ sbMessage }}</v-snackbar>
</template>

<script>
import Navbar from '@/components/NavBar.vue';

import axios from 'axios';
import moment from 'moment';
import auth from '@/helpers/auth';
import { backendURL } from '../config/config';

axios.defaults.baseURL = backendURL;

import { state } from '@/socket';

export default {
  data() {
    return {
      text: '',
      messages: [],
      msgLoading: false,
      loading: true,
      other: {},
      userFound: false,
      snackbar: false,
      sbMessage: '',
      color: '',
      auth,
      moment,
      backendURL,
    };
  },
  components: { Navbar },
  props: ['hash'],
  mounted() {
    if (this.hash == null) {
      return this.$router.push('/home');
    }

    axios
      .get(`/api/users/hash/${this.hash}`)
      .then((res) => {
        // console.log(res.data);
        this.other = res.data;
        this.userFound = true;
        axios
          .get(`/api/messages/${this.other._id}`, {
            headers: { Authorization: auth.token() },
          })
          .then((res) => {
            this.messages = res.data.chat;
            // console.log(this.messages);
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch(() => {
        this.loading = false;
        this.snackbar = true;
        this.sbMessage = 'Error loading user!';
        this.color = 'red';
      });
  },
  watch: {
    newMsg(val) {
      if (val.sent_from._id == this.other._id) {
        val.readAt = Date.now();
        this.messages.unshift(val);
      }
    },
    messagesRead(val) {
      if (val == this.other._id) {
        axios
          .get(`/api/messages/${this.other._id}`, {
            headers: { Authorization: auth.token() },
          })
          .then((res) => {
            this.messages = res.data.chat;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onlineStatus(val) {
      this.other.lastSeen = Date.now();
      if (val && !this.loading)
        this.messages.forEach((message) => {
          if (!message.receivedAt && message.sent_to == this.other._id)
            message.receivedAt = Date.now();
        });
    },
  },
  computed: {
    onlineStatus() {
      return (
        this.onlineUsers.findIndex((item) => item.userId == this.other._id) !==
        -1
      );
    },
    onlineUsers() {
      return state.onlineUsers;
    },
    messagesRead() {
      return state.userRead;
    },
    newMsg() {
      return state.msg;
    },
    firstMsgToday() {
      const d = new Date().setHours(0, 0, 0, 0);
      const msgs = this.messages.filter(
        (item) => new Date(item.createdAt) > new Date(d)
      );
      if (msgs.length == 0) return [];
      return msgs[msgs.length - 1]._id;
    },
    firstMsgYesterday() {
      const d = new Date().setHours(0, 0, 0, 0);
      const msgs = this.messages.filter(
        (item) =>
          new Date(item.createdAt) > new Date(d - 1000 * 60 * 60 * 24) &&
          new Date(item.createdAt) < new Date(d)
      );
      if (msgs.length == 0) return [];
      return msgs[msgs.length - 1]._id;
    },
  },
  methods: {
    sendMessage() {
      this.msgLoading = true;
      axios
        .post(
          `/api/messages/${this.other._id}`,
          {
            content: this.text,
            receivedAt: this.onlineStatus ? Date.now() : null,
          },
          { headers: { Authorization: auth.token() } }
        )
        .then((res) => {
          this.msgLoading = false;
          this.messages.unshift(res.data.message);
          this.text = '';
          if (this.messagesRead == this.other._id)
            this.messages[0].readAt = Date.now();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    firstMsgLastWeek() {
      const d = new Date().setHours(0, 0, 0, 0);
      let msgs = [];

      for (let i = 1; i < 7; i++) {
        let msg = this.messages.filter(
          (item) =>
            new Date(item.createdAt) < new Date(d - 1000 * 60 * 60 * 24 * i) &&
            new Date(item.createdAt) >
              new Date(d - 1000 * 60 * 60 * 24 * (i + 1))
        );
        // if (msg.length != 0) msgs.push(msg[msg.length - 1]);
      }

      return msgs;
    },
  },
};
</script>

<style scoped>
.v-card::-webkit-scrollbar {
  display: none;
}
</style>
