<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-table class="pa-2 ma-2">
    <thead>
      <tr>
        <th class="text-left">Reported</th>
        <th class="text-left">Reported By</th>
        <th class="text-left">Reason</th>
        <th class="text-center">Status</th>
        <th class="text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in reports" :key="item._id">
        <td>
          {{ item.reportedUser.fullName }}
        </td>
        <td>{{ item.submittedBy.fullName }}</td>
        <td class="overflow-hidden">{{ item.reason }}</td>
        <td class="text-center">
          <v-chip
            variant="elevated"
            :color="item.status == 'open' ? 'orange-darken-2' : 'success'"
            rounded="lg"
            >{{ item.status }}</v-chip
          >
        </td>
        <td class="text-center" style="max-width: 150px">
          <v-btn
            size="small"
            class="mx-auto my-1"
            variant="outlined"
            color="primary-darken-1"
            prepend-icon="fa fa-eye"
            @click="handleView(item)"
            >Details</v-btn
          >
          <v-btn
            v-if="item.status == 'open'"
            @click="handleClose(item._id)"
            size="small"
            class="mx-auto my-1"
            variant="outlined"
            color="orange"
            prepend-icon="fa fa-xmark"
            >Close</v-btn
          >
        </td>
      </tr>
    </tbody>
  </v-table>

  <v-dialog v-model="detailsDialog" width="600">
    <v-card>
      <v-card-title>
        Report on {{ selectedReport.reportedUser.fullName }}
        <v-btn
          @click="detailsDialog = !detailsDialog"
          class="float-right ma-0 pa-0"
          size="x-small"
          variant="elevated"
          icon="fa fa-xmark"
          color="red"
        ></v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <span class="d-block my-2">
          <span class="text-bold"> Issued On:</span>
          {{
            moment(selectedReport.createdAt).format('ddd DD/MM/YY [at] HH:mm')
          }}</span
        >
        <span class="d-block my-2">
          <span class="text-bold"> Submitted By:</span>
          {{ selectedReport.submittedBy.fullName }}</span
        >
        <span class="d-block my-2">
          <span class="text-bold"> Reason:</span>
          {{ selectedReport.reason }}</span
        >
        <span class="d-block my-2">
          <span class="text-bold"> Status: </span>
          <v-chip
            variant="elevated"
            :color="
              selectedReport.status == 'open' ? 'orange-darken-2' : 'success'
            "
            rounded="lg"
            >{{ selectedReport.status }}</v-chip
          ></span
        >
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="selectedReport.status == 'open'">
        <v-btn
          @click="suspendUser()"
          style="text-transform: none"
          variant="elevated"
          color="red-darken-2"
          >Suspend User</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="handleClose(selectedReport._id)"
          style="text-transform: none"
          variant="elevated"
          color="primary"
          >Close Report</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import auth from '@/helpers/auth';
import { backendURL } from '@/config/config';

export default {
  components: {},
  data() {
    return {
      reports: [],
      selectedReport: {},
      detailsDialog: false,
      moment,
      backendURL,
    };
  },
  mounted() {
    axios.defaults.headers.common['Authorization'] = auth.adminToken();

    axios.get('/api/admins/reports').then((res) => {
      console.log(res.data);
      this.reports = res.data.reports;
    });
  },
  methods: {
    handleView(report) {
      // console.log(user);
      this.selectedReport = report;
      this.detailsDialog = true;
    },
    suspendUser() {
      axios
        .post(
          `/api/admins/users/suspend/${this.selectedReport.reportedUser._id}?suspend=true`
        )
        .then((res) => {
          if (!res.data.error) {
            console.log('user suspended');
            this.handleClose(this.selectedReport._id);
          }
        });
    },
    handleClose(report) {
      axios
        .post(`/api/admins/reports/${report}`)
        .then((res) => {
          if (!res.data.error) {
            console.log('Report closed');
            const index = this.reports.findIndex((item) => item._id == report);
            if (index !== -1) this.reports[index].status = 'closed';
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style scoped>
* {
  font-family: Helvetica;
}
</style>
