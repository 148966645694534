import { createRouter, createWebHistory } from 'vue-router';
import auth from '../helpers/auth';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Home from '../views/Home.vue';
import Profile from '../views/Profile.vue';
import Chat from '../views/Chat.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import PageNotFound from '../views/PageNotFound.vue';

import AdminLogin from '../views/admin/Login.vue';
import AdminHome from '../views/admin/Home.vue';
import ManageUsers from '../views/admin/ManageUsers.vue';
import SuspendedUsers from '../views/admin/SuspendedUsers.vue';
import DeletedUsers from '../views/admin/DeletedUsers.vue';
import ChangePassword from '../views/admin/ChangePassword.vue';
import Reports from '../views/admin/Reports.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresNotAuth: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresNotAuth: true,
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'My Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile/:hash',
    name: 'Profile',
    props: true,
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chat/:hash',
    props: true,
    component: Chat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  { path: '/:pathMatch(.*)*', name: 'Not Found', component: PageNotFound },
  {
    path: '/admin',
    name: 'Admin Login',
    component: AdminLogin,
  },
  {
    path: '/admin',
    name: 'Admin Panel',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAdmin: true,
    },
    children: [
      {
        path: 'home',
        name: 'Admin Home',
        component: AdminHome,
        meta: {
          requiresAdmin: true,
        },
      },
      {
        path: 'manage-users',
        name: 'Manage Users',
        component: ManageUsers,
      },
      {
        path: 'suspended-users',
        name: 'Suspended Users',
        component: SuspendedUsers,
      },
      {
        path: 'deleted-users',
        name: 'Deleted Users',
        component: DeletedUsers,
      },
      {
        path: 'change-password',
        name: 'Change Admin Password',
        component: ChangePassword,
      },
      {
        path: 'reports',
        name: 'Manage Reports',
        component: Reports,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Routes requiring the user logged in
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await auth.authenticated()) next();
    else next('/');
  }
  // Routes requiring the user logged out
  else if (to.matched.some((record) => record.meta.requiresNotAuth)) {
    if (await auth.authenticated()) next('/home');
    else next();
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (auth.adminToken() != null) next();
    else next('/admin');
  } else {
    next();
  }
});

export default router;
